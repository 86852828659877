import { useState, useRef, useEffect } from 'react';
import { ErrorMessage, getIn, useFormikContext } from 'formik';
import classnames from 'classnames/bind';
import { emitCustomEvent } from 'react-custom-events'
import { useMeasure, useMediaQuery } from '@react-hookz/web/esnext'
import { CharIcon, FormError, ToolTip } from '~/components'
import { searchLocation, searchNearby, fetchStreetNumbers, fetchLocationInfo } from '~/lib/api';
import { formatAddress } from '~/utils/format';
import { useDidMountEffect, usePrevious } from '~/utils/hooks';
import { default as SearchIcon } from './SearchIcon';
import * as styles from './index.module.css';
import { useTranslation } from 'react-i18next';

const cx = classnames.bind(styles)

const hasValue = field => field.value !== '' && field.value !== '-' && field.value?.address !== undefined
const isStreetOnly = value => !/\d/.test(value)

export default function LocationSearch({
  field, // { name, value, onChange, onBlur }
  form: { errors, touched, setFieldValue, setStatus }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  innerRef,
  useLocation = false,
  ...props
}) {

  const { t } = useTranslation()
  const minChars = 3;
  const [listSize, listRef] = useMeasure();
  const [listContentSize, listContentRef] = useMeasure();
  const isMobile = useMediaQuery('only screen and (max-width: 969px)')

  const { values } = useFormikContext()

  const [searchValue, setSearchValue] = useState(hasValue(field) ? formatAddress(field.value.address) : '');
  const [locations, setLocations] = useState();
  const [allNumbers, setAllNumbers] = useState();
  const [numbers, setNumbers] = useState();

  const [currentIndex, setCurrentIndex] = useState(null);
  // NOTE: Clear all on change when field.value is set (ie. when returning to a view with a selected location)
  const [selectedItem, setSelectedItem] = useState(hasValue(field) ? true : null);

  const [isVisible, setIsVisible] = useState(false);
  const [isStreetNumbers, setIsStreetNumbers] = useState(false);
  const [hasGeolocation, setHasGeolocation] = useState(false);
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const isSelected = typeof field.value === 'object'

  if (innerRef === undefined) innerRef = useRef()

  // Reset component when the parent form is reset
  // NOTE: This only works when you have selected an address!
  const prevField = usePrevious(field);

  useDidMountEffect(() => {
    // console.log('field value:', field.value);
    const reset = () => {
      setLocations();
      setNumbers();
      setCurrentIndex(null);
      setSelectedItem(null);
      setIsVisible(false);
      setIsStreetNumbers(false);
      setAddressError(false);
    }
    if (!hasValue(field) && hasValue(prevField)) {
      setSearchValue('');
      reset();
    }
    else if (hasValue(field)) {
      setSearchValue(formatAddress(field.value.address))
      reset();
    }
  }, [field.value]);

  // NOTE: Handle lost focus problem with the reorder component
  useEffect(() => {
    if (innerRef.current && window?.FIELD_FOCUS?.[field.name] === true) {
      innerRef.current.focus()
    }
  }, [innerRef])
 
  // Set scrollTop when switching lists
  useEffect(() => {
    if (isVisible && listRef.current) {
      listRef.current.scrollTop = isMobile ? 0 : 50000
    }
  }, [locations, numbers, isVisible, listRef])

  useEffect(() => {
    if (useLocation && navigator.geolocation) {
      setHasGeolocation(true);
    }
  }, [])

  useEffect(() => {
    if (innerRef.current) {
      // NOTE: Hack to get Rix adresses working
      try {
        if (selectedItem?.address !== undefined && (isStreetNumbers || selectedItem.address.region !== 'Stockholm')) {
          const pos = selectedItem.address.streetName.length + 1;
          innerRef.current.setSelectionRange(pos, pos);
        }
      }
      catch (e) {
        console.error('ERROR')
        console.log(selectedItem)
      }
      // NOTE: This does not work...
      //
      // else if (isFocused) {
      //   setTimeout(() => {
      //     const pos = searchValue.length
      //     innerRef.current.setSelectionRange(pos, pos)
      //   }, 100)
      // }
    }
  }, [selectedItem, isStreetNumbers, innerRef])


  // -----------------------------------------------------
  //  Event handlers 
  // -----------------------------------------------------

  const handleChange = event => {
    const v = event.target.value;
    setSearchValue(v);
    setCurrentIndex(null);

    let isSN = isStreetNumbers;

    /*

      NOTE: Do not clear the field when editing if there is a selected item already

    // If we have a selected item or Formik field value and are making changes
    if (selectedItem != null || hasValue(field)) {
      const o = hasValue(field) ? field.value : selectedItem

      // If state is searching for streetnumbers and the street or city is removed, switch to location search
      if (isSN && (v.indexOf(o?.address?.streetName) === -1 || v.indexOf(o?.address?.city) === -1)) {
        isSN = false;
      }
      if (!isSN) {
        setSelectedItem(null);
        setIsStreetNumbers(false);
      }
      // Clear Formik value
      setFieldValue(field.name, '-', false);
    }
    */

    // Hide tooltip when changing input
    if (toolTipVisible) {
      setToolTipVisible(false);
    }

    // Hide error
    if (addressError !== false) {
      setAddressError(false);
    }

    if (v.length >= minChars) {
      // console.log(v);

      if (!isSN) {
        searchLocation(v, isStreetOnly(v), field.name !== 'departure' ? values.region : undefined).then((locations) => {
          if (!isMobile) locations.reverse();
          setIsVisible(true);
          setLocations(locations);
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error(error.message);
          }
        });
      }
      else {
        setNumbers(getFilteredNumbers(v));
        setIsVisible(true);
      }
    }
    else if (v === '' && hasValue(field)) {
      setFieldValue(field.name, '-');
    }
    else if (isVisible) {
      setIsVisible(false);
    }
  };

  const handleKeyDown = event => {
    // Prevent arrow up/down to change cursor position in the input field
    if (event.keyCode == 38 || event.keyCode == 40) {
      event.preventDefault();
    }
  };

  const handleKeyUp = event => {
    if (numbers?.length > 0 || locations?.length > 0) {
      if (event.keyCode == 13 && currentIndex != null) {
        handleSelect(currentIndex);
      }
      else if (event.keyCode == 38 || event.keyCode == 40) {
        if (currentIndex === null) {
          const index = isMobile ? 0 :
            isStreetNumbers ? numbers.length - 1 : locations.length - 1;
          setCurrentIndex(index);
        }
        else {
          const list = isStreetNumbers ? numbers : locations;
          let ci = (event.keyCode == 38 ? currentIndex - 1 : currentIndex + 1);
          if (ci >= list.length) ci = list.length - 1;
          else if (ci < 0) ci = 0;
          setCurrentIndex(ci);

          if (listRef.current) {
            const listHeight = listSize.height;
            const itemHeight = listContentSize.height / list.length;

            const itemTop = ci * itemHeight;
            const scrollTop = listRef.current.scrollTop;
            if (itemTop + itemHeight > scrollTop + listHeight) {
              listRef.current.scrollTop = (itemTop + itemHeight) - listHeight;
            }
            else if (itemTop < scrollTop) {
              listRef.current.scrollTop = itemTop;
            }
          }
        }
      }
    }
  };

  const handleFocus = event => {
    // NOTE: Handle lost focus problem with the reorder component
    if (window.FIELD_FOCUS === undefined) window.FIELD_FOCUS = {}
    window.FIELD_FOCUS[field.name] = true
    setIsVisible(true)
    if (props.onFocus) props.onFocus(event)
  };

  const handleBlur = event => {
    // if (!useLocation) setIsVisible(false)
    // NOTE: Handle lost focus problem with the reorder component
    window.FIELD_FOCUS[field.name] = false
    setIsVisible(false)
    field.onBlur(event)
    if (props.onBlur) props.onBlur(event)
  };

  const handleSelect = index => {
    const item = isStreetNumbers ? numbers[index] : locations[index];

    if (isStreetNumbers) {
      const parts = item.split(' ');
      selectedItem.address.streetNumber = parseInt(parts[0], 10);
      selectedItem.address.entrance = parts[1] || '';
      // NOTE: Check if the above updating of selectedItem works as expected
      setSearchValue(formatItem(selectedItem));
    }
    else {
      setSelectedItem(item);

      if (isStreetOnly(searchValue) && !isStreetNumbers && item.locationTypes.indexOf('ADDRESS') !== -1) {
        setSearchValue(item.address.streetName + ' , ' + item.address.city);
      }
      else {
        setSearchValue(formatItem(item));
      }
    }

    // NOTE: Hack to get Rix adresses working. Should show message to input street number
    if (item?.address !== undefined && item.locationTypes.indexOf('ADDRESS') !== -1 && item.address.region !== 'Stockholm' && item.address.streetNumber === undefined) {
      console.log('Searching in other region')
      setIsVisible(false);
      setToolTipVisible(true);
    } 
    else if (isStreetOnly(searchValue) && !isStreetNumbers && item.locationTypes.indexOf('ADDRESS') !== -1) {
      fetchStreetNumbers(item.address.streetName, item.address.city).then((numbers) => {
        if (!isMobile) numbers.reverse();
        setCurrentIndex(null);
        setAllNumbers(numbers);
        setNumbers(numbers);
        setIsStreetNumbers(true);
        setIsVisible(true);
      })
      .catch((error) => {
        console.error(error.message);
      });
    }
    else {
      setIsVisible(false);
      fetchLocationInfo(isStreetNumbers ? selectedItem : item).then((info) => {
        console.log(info);
        setFieldValue(field.name, info);
      })
      .catch((error) => {
        console.error(error.message);
        // NOTE: We cannot use setFieldError because that error will be overridden when the validation runs
        // so we use internal state and set a special "error" when an address could not be found.
        if (error?.response?.error === 'LOCATION_NOT_FOUND') {
          setAddressError(error?.response?.message)
        }
      });
    }

  };

  const fetchGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (result) => {
        console.log(result);
        searchNearby(result.coords).then((locations) => {
          if (!isMobile) locations.reverse();
          console.log(locations);
          setCurrentIndex(null);
          setIsVisible(true);
          setLocations(locations);
        })
        .catch((error) => {
          console.error(error.message);
        });
      }, 
      (error) => {
        console.error(error);
      }
    );
  };

  const handleIconClick = () => {
    if (field.value?.address) {
      emitCustomEvent('ZOOM_LOCATION', field.value)
    }
  }

  // -----------------------------------------------------
  //  Utils
  // -----------------------------------------------------

  const getFilteredNumbers = value => {
    // Takes an input address and removes all but the street number and optional entrance
    const query = value
      .replace(selectedItem.address.streetName, '')
      .replace(selectedItem.address.city, '')
      .replace(',', '')
      .trim()
      .replace(/(\d+)\s?([A-Z]*)/, '$1 $2')
      .trim()
      .toUpperCase();

    return allNumbers.filter(v => v.indexOf(query) === 0);
  }

  const hasItems = () => {
    const list = isStreetNumbers ? numbers : locations;
    return (list && list.length > 0);
  }

  const formatItem = item => {
    return typeof item === 'string' ? formatAddress(selectedItem.address, item) : formatAddress(item.address);
  };

  // -----------------------------------------------------
  //  Rendering 
  // -----------------------------------------------------

  const renderList = () => {
    const list = isStreetNumbers ? numbers : locations;
    if (list && list.length > 0) {
      return (
        <div className={styles.dropdown} ref={listRef}>
          <ul ref={listContentRef}>
          {list.map((item, index) => (
            // NOTE: The onMouseDown prevents the onBlur event on the input field triggering and hiding the drop down before the onClick is called
            <li
              className={cx(styles.item, { selected: index === currentIndex })} 
              key={index} 
              onMouseDown={event => event.preventDefault()} onClick={() => handleSelect(index)}
            >{formatItem(item)}</li>
          ))}
          </ul>
        </div>
      );
    }
  };

  // NOTE: Override field error with the internal error stat if set
  const error = addressError || getIn(errors, field.name)
  const touch = addressError !== false || getIn(touched, field.name)

  return (
    <div className={styles.container}>
      <ToolTip title={t('info-enter-street-number')} isVisible={toolTipVisible} />
      <div className={cx(styles.fieldwrapper, { dropDownVisible: isVisible && hasItems() })}>
        <SearchIcon className={styles.icon} active={isVisible || searchValue !== ''} />
        {isSelected && <CharIcon char={'ABCDE'[props.fieldIndex]} className={styles.charIcon} onClick={handleIconClick} />}
        <input 
          type="text" 
          name={field.name}
          autoComplete="off" 
          autoCorrect="off" 
          autoCapitalize="off" 
          spellCheck="false"
          ref={innerRef}
          value={searchValue} 
          onChange={handleChange} 
          onKeyDown={handleKeyDown} 
          onKeyUp={handleKeyUp} 
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={props.placeholder}
          tabIndex={props.tabIndex !== undefined ? props.tabIndex : 0}
        />
        {/*hasGeolocation && <button type="button" tabIndex="-1" className={styles.locationbutton} onClick={fetchGeolocation}></button>*/}
        {renderList()}
      </div>
      {error && touch && <FormError useMotion={false}>{error}</FormError>}
      {/* 
        
          NOTE: This is a workaround for the ErrorMessage component not working correctly with the Reorder.Group 
          causing the error message to display on the wrong location, duplicate messages and other bugs.

      */}
      {/* <ErrorMessage name={field.name} component={FormError} /> */}
    </div>
  );
};


