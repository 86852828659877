{
  "sv": {
    "translation": {
      "header-book-taxi": "Boka taxi",
      "placeholder-origin-location": "Jag vill resa från...",
      "placeholder-destination-location": "Jag vill resa till...",
      "placeholder-add-stop": "Lägg till ett stopp",
      "button-add-stop": "Lägg till ett stopp",
      "button-go-now": "Åka nu",
      "button-go-later": "Åka senare",
      "button-no-available-cars": "Inga tillgängliga bilar",
      "booking-login-info": "För att slutföra din bokning kommer det att krävas att du bekräftar ditt telefonnummer i slutfasen av bokningen.",
      "validation-departure-required": "Du måste välja en upphämtningsplats.",
      "validation-destination-required": "Du måste välja en destination.",
      "validation-airport-not-allowed": "Du kan inte välja en flygplats som en viaadress.",
      "info-enter-street-number": "Vänligen fyll i gatunummer",
      "error-no-estimate": "Det verkar som du har dålig uppkopling, var vänlig försök igen eller ring vår växel 08-15 00 00 så hjälper de dig att boka.",
      
      "header-when-do-you-want-to-go": "När vill du åka?",
      "header-when-does-your-flight-land": "När landar ditt flyg?",
      "button-continue": "Fortsätt",
      "placeholder-flight-number": "Flightnummer",
      "info-flight-number": "Vi bevakar flygets ankomsttid",
      "title-baggage": "Incheckat baggage",
      "info-baggage": "Vi tar hänsyn till att det kan ta tid att få ditt incheckade bagage i handen efter landning",
      "validation-date-not-valid": "Datum och tid är inte giltigt.",
      "validation-date-too-far-ahead": "Datum och tid är för långt fram.",
      "validation-need-date": "Du måste välja en dag och tid.",
      "validation-need-flight-number": "Du måste fylla i flightnummer.",
      "title-fully-booked": "Tiden är fullbokad",
      "message-select-other-time": "Just nu är förbokningar fullbokade minst en timme framåt via webben, om möjligt välj en annan tid eller kontakta vår växel på 08-15 00 00 så hjälper de dig.",
      "message-select-next-available-time": "Välj en annan tid eller den föreslagna tiden.<br/><br/>Nästa lediga tid är {{time}}",
      "button-select-other-time": "Välj annan tid",
      "button-select-available-time": "Välj föreslagen tid",
      "button-ok": "Ok",

      "header-how-do-you-want-to-go": "Hur vill du åka?",
      "cartype-taxi": "Taxi",
      "cartype-large-car": "Storbil",
      "cartype-business": "Business",
      "num-persons-3": "3 personer",
      "num-persons-4": "4 personer",
      "num-persons-7": "7 personer",
      "approx-min": "ca {{minutes}} min",
      "title-fixed-price": "Fastpris",
      "title-booking-options": "Tillval",
      "info-booking-options": "Lägg till extra tillval",
      "title-pet-option": "Resa med djur",
      "title-stationwagon-option": "Kombi",
      "title-childseat-option": "Bakåtvänd bilbarnstol (100kr)",
      "info-childseat-option": "För bilbarnstol, ring vår växel på 08-150000",
      "title-booster-option": "Bälteskudde",
      "info-booster-option": "För barn över 4 år eller 15kg",
      "button-done": "Klar",

      "label-i-want-to-go-now": "Jag vill åka nu",
      "label-from": "Från",
      "label-to": "Till",
      "label-when": "När",
      "label-payment": "Betalning",
      "label-receipt": "Kvitto",
      "label-how": "Hur",
      "label-fixed-price": "Fastpris",
      "label-price-information": "Prisinformation",
      "label-total": "Totalt",
      "label-pet-option": "Resa med djur",
      "label-stationwagon-option": "Kombi",
      "label-booster-1-option": "1 bälteskudde",
      "label-booster-2-option": "2 bälteskuddar",
      "label-booking-number": "Bokningsnummer",
      "info-available-in": "Tillgänglig om ca {{minutes}} minuter",
      "button-download-receipt": "Visa kvitto",
      "info-receipt-not-available": "Kvitto ej tillgängligt",
      "info-receipt-pending": "Kvitto tillgängligt efter registrerad betalning",
      "info-receipt-refunded": "Återbetalning genomförd",
      "info-num-vouchers_one": "{{count}} rabattkod tillgänglig",
      "info-num-vouchers_other": "{{count}} rabattkoder tillgängliga",

      "header-confirm-phone": "Bekräfta ditt telefonnummer",
      "info-confirm-phone": "En kod kommer att skickas till ditt nummer. Vi använder ditt nummer för att smsa dig om viktiga uppdateringar på din resa.",
      "info-confirm-phone-login": "För att logga in måste du bekräfta ditt telefonnummer.",
      "button-cancel": "Avbryt",
      "validation-only-numbers": "Får bara innehålla siffror.",
      "validation-need-phone": "Du måste fylla i telefonnummer.",
      "validation-length-phone": "Måste vara mellan 8 och 14 siffor.",

      "header-enter-your-code": "Skriv in din kod",
      "info-enter-the-code-sent-to": "Skriv in koden som skickades till nummer {{dialCode}} {{phone}}",
      "button-did-not-get-code": "Jag fick ingen kod",

      "header-enter-your-email": "Ange din epostadress",
      "placeholder-enter-your-email": "Ange din epostadress",
      "info-we-use-your-email": "Vi använder din epost för att skicka digitala kvitton till dig.",
      "validation-invalid-email": "Ogiltig epostadress.",
      "validation-need-email": "Du måste fylla i epostadress.",

      "header-welcome-back": "Välkommen tillbaka!",
      "info-your-phone-is-linked": "Ditt telefonnummer är länkat till epostadressen {{maskedEmail}}. Vänligen ange verifieringskoden som vi har mailat dig.",
      "button-select-other-email": "Välj annan epost",
      "change-email-message": "Om du loggar in med en annan epostadress kommer bokningar du gjort med din tidigare adress inte synas i din historik.",

      "header-enter-name": "Ange ditt namn",
      "info-driver-confirm-pickup": "Detta hjälper vår chaufför att bekräfta att det är du när de hämtar dig.",
      "placeholder-firstname": "Förnamn",
      "placeholder-lastname": "Efternamn",
      "validation-need-firstname": "Du måste fylla i förnamn.",
      "validation-need-lastname": "Du måste fylla i efternamn.",

      "header-terms-and-conditions": "Regler och villkor",
      "info-accept-terms": "Vänligen läs och godkänn våra <link1>regler och villkor</link1>.",
      "title-accept-terms": "Jag bekräftar att jag läst och accepterar vilkoren",
      "title-accept-marketing": "Jag vill få erbjudanden, nyheter och uppdateringar från Taxi Stockholm",
      "button-confirm": "Fortsätt",

      "header-confirm-your-booking": "Bekräfta din bokning",
      "placeholder-name": "Namn",
      "placeholder-phone": "Telefonnummer",
      "title-payment": "Betalning",
      "title-vouchers": "Rabattkoder",
      "title-voucher": "Rabattkod:",
      "title-pay-in-car": "Betala i taxin",
      "title-pay-in-car-sender": "Betala i taxin (avsändaren betalar)",
      "button-add-new-card": "Lägg till nytt betalkort",
      "button-add-new-account": "Lägg till nytt konto",
      "button-add-new-voucher": "Lägg till ny rabattkod",
      "title-message-to-driver": "Meddelande till föraren",
      "info-message-to-driver": "Här kan du beskriva hur föraren kan hitta dig.",
      "placeholder-reference": "Referens",
      "info-accept-booking-terms": "Genom att resa med oss så godkänner du våra <link1>resevillkor</link1>.",
      "title-message": "Meddelande",
      "validation-need-name": "Du måste fylla i namn.",
      "validation-need-reference": "Du måste fylla i referens.",
      "button-book-now": "Boka nu",
      "error-payment": "Något gick fel med betalningen. Vänligen försök igen.",

      "button-cancel-booking": "Avboka",
      "button-close": "Stäng",
      "label-name": "Namn",
      "label-phone": "Telefonnummer",
      "label-message-to-driver": "Meddelande till föraren",
      "header-thanks-for-your-booking": "Tack för din bokning!",
      "info-booking-done": "Du hittar dina bokningar under den gula ikonen uppe till höger. Genom att resa med oss så godkänner du våra <link1>resevillkor</link1>.",
      "title-car-and-eta": "{{taxi}} ankommer ca {{eta}}",
      
      "tooltip-here-is-your-bookings": "Här finns dina bokningar",
      "header-your-bookings": "Dina bokningar",
      "info-arrives-apx": "Ca:",
      "label-date": "Datum",
      "label-flight-number": "Flightnummer",
      "label-baggage": "Bagage",
      "label-account": "Konto",
      "label-booking-options": "Tillval",
      "label-yes": "Ja",
      "label-no": "Nej",
      "label-message": "Meddelande",
      "button-show-more": "Visa mer",
      "button-show-less": "Visa mindre",

      "info-login-to-update-profile": "Logga in för att lägga till betalmedel, se resehistorik eller uppdatera din profil.",
      "label-firstname": "Förnamn",
      "label-lastname": "Efternamn",
      "label-mobile-phone": "Mobiltelefon",
      "label-communications": "Kommunikation",
      "info-change-phone": "För att använda ett annat telefonnummer behöver du logga ut och skapa ett nytt konto.",

      "reset-booking-message": "Vill du verkligen avbryta din bokning?",
      "reset-login-message": "Vill du verkligen avbryta din inloggning?",
      "button-login": "Logga in",
      "button-yes": "Ja",
      "button-no": "Nej",

      "price_basePrice": "Grundpris",
      "price_vat": "Varav moms",
      "price_terminalFee": "Terminalavgift",
      "price_environmentalFee": "Miljöavgift",
      "price_waitingFee": "Väntetaxa",
      "price_fixedAdditionalFee": "Fasta tillägg i bil",
      "price_tip": "Dricks",

      "button-read-more": "Läs mer",

      "status-pre-booked": "Förbokad",
      "status-searching": "Söker bil",

      "menu-my-bookings": "Mina resor",
      "header-previous-bookings": "Tidigare resor",

      "button-book-taxi": "Boka taxi",
      "button-book-delivery": "Boka bud",

      "label-as-soon-as-possible": "Så snart som möjligt",
      "label-price": "Pris",

      "header-book-delivery": "Boka bud",
      "placeholder-pickup-address": "Upphämtningsadress",
      "placeholder-delivery-address": "Leveransadress",
      "button-prebook-delivery": "Förboka bud",
      "button-book-delivery-now": "Boka bud nu",
      "validation-pickup-address-required": "Du måste välja en upphämtningsadress.",
      "validation-delivery-address-required": "Du måste välja en leveransadress.",

      "header-when-should-we-pickup": "När ska vi hämta?",

      "header-what-would-you-like-to-send": "Vad vill du skicka?",
      "title-ENVELOPE": "Kuvert",
      "title-BOX": "Liten låda",
      "title-BAG": "Kasse",
      "title-MOVING_BOX": "Flyttlåda",
      "weight-0-20": "Vikt 0-20 kg",
      "info-delivery-categories": "Ovanstående kategorier används för att avgöra vilken biltyp som kommer att behövas för ditt bud (max bilstorlek är kombi).",
      "info-delivery": "Om du vill skicka mer skrymmande gods eller har frågor, ring vår budväxel på 08-612 60 00.<br/> Mer information om vad som gäller för budbokning via webb hittar du <button1>här</button1>.",
      "info-delivery-terms": "<strong>Mer information om bud via vår hemsida</strong><br/><br/><ul><li>Vår budservice hanterar ej farligt gods (tex bensin, cigarettändare, gasol), varmhållen mat, tobak, djur eller större flyttransporter.</li><li>Maxvikt per kolli är 20 kg vid budbokning via vår hemsida.</li><li>10 min väntetid ingår i priset för budbokning, därefter debiteras väntetaxa.</li><li>Ev. färjeavgift kan tillkomma om rutten innefattar en färja med avgift.</li><li>Ev. terminalavgifter är inkluderade i priset för vissa destinationer. Se aktuella prisuppgifter för terminalavgifter <a href=\"/sv/hjalpcenter/priser\" target=\"_blank\">här</a>.</li><li>I de fall föraren inte är tillåten att lämna godset vid dörren och mottagaren ej är tillgänglig vid avlämning, kommer godset att returneras till sändaren. En ev. returresa debiteras.</li><li>Vid betalningsmetod Betala i taxin, så måste resan betalas av avsändaren innan den påbörjas.</li><li>Vi kan tyvärr inte hjälpa till med inköp av varor.</li><li>Vi kan tyvärr inte hämta ut paket där legitimation krävs.</li><li>Sätet i bilen kommer tyvärr inte att kunna fällas ner vid budbokning via hemsidan. Om du vill skicka skrymmande gods (gods med stor volym/mått) eller har frågor, kontakta vår budväxel på 08-612 60 00.</li></ul>",
      "validation-package-required": "Du måste välja åtminstone en typ av bud du vill skicka.",

      "header-whos-the-sender": "Vem skickar?",
      "placeholder-name-on-door": "Namn på dörren",
      "placeholder-number-of-stairs": "Antal trappor",
      "placeholder-door-code": "Eventuell portkod",

      "header-whos-the-receiver": "Vem tar emot?",
      "placeholder-name-receiver-on-door": "Namn mottagare / på dörren",
      "placeholder-phone-receiver": "Telefon mottagare",
      "title-extra-information": "Extra information",
      "info-extra-information": "Här kan du fylla i extra information till föraren.",
      "title-leave-outside": "Lämna utanför",
      "validation-name-receiver-required": "Du måste fylla i namn på mottagare.",

      "label-name-on-door": "Namn på dörren",
      "label-number-of-stairs": "Antal trappor",
      "label-door-code": "Eventuell portkod",
      "label-name-receiver-on-door": "Namn mottagare / på dörren",
      "label-phone-receiver": "Telefon mottagare",
       
      "type-ENVELOPE": "{{count}} kuvert",
      "type-BOX_one": "{{count}} liten låda",
      "type-BOX_other": "{{count}} små lådor",
      "type-BAG_one": "{{count}} kasse",
      "type-BAG_other": "{{count}} kassar",
      "type-MOVING_BOX_one": "{{count}} flyttlåda",
      "type-MOVING_BOX_other": "{{count}} flyttlådor",

      "header-account": "Konto",
      "header-accounts": "Konton",
      "header-card": "Betalkort",
      "header-cards": "Betalkort",
      "header-vouchers": "Rabattkoder",
      "info-no-accounts": "Du har inte lagt till några konton ännu.",
      "info-no-cards": "Du har inte lagt till några betalkort ännu.",
      "info-no-vouchers": "Du har inte lagt till några rabattkoder ännu.",
      "button-add-card": "Lägg till betalkort",
      "button-add-account": "Lägg till konto",
      "button-add-voucher": "Lägg till rabattkod",
      "button-save": "Spara",
      "info-confirm-remove-card": "Är du säker på att du vill ta bort betalkortet från din profil?",
      "header-add-account": "Lägg till konto",
      "header-add-voucher": "Lägg till rabattkod",
      "header-details": "Uppgifter",
      "placeholder-account-number": "Kontonummer",
      "placeholder-account-password": "Lösenord",
      "placeholder-voucher-code": "Rabattkod",
      "validation-need-voucher-code": "Du måste ange en kod.",
      "validation-need-account-number": "Du måste ange kontonummer.",
      "validation-need-account-password": "Du måste ange lösenord.",
      "expires-at": "Giltig t.o.m {{date}}",
      "free-trip": "Gratis resa*",
      "info-voucher-max": "Rabatt på första {{amount}}kr",
      "info-voucher-codes": "Det är bara möjligt att använda en rabattkod per resa. Se våra <link1>betalningsvillkor</link1> för mer information.",

      "button-send-confirmation": "Epostbekräftelse",
      "header-send-confirmation": "Skicka epostbekräftelse",
      "placeholder-email": "Epostadress",
      "button-send": "Skicka",
      "info-confirmation-sent": "En bekräftelse har skickats."
    }
  },
  "en": {
    "translation": {
      "header-book-taxi": "Book taxi",
      "placeholder-origin-location": "Where can we pick you up?",
      "placeholder-destination-location": "Where do you want to go?",
      "placeholder-add-stop": "Add a stop",
      "button-add-stop": "Add a stop",
      "button-go-now": "Book taxi now",
      "button-go-later": "Pre-book",
      "button-no-available-cars": "No available cars",
      "booking-login-info": "To complete your booking, you will be required to verify your phone number.",
      "validation-departure-required": "You must select a pickup location.",
      "validation-destination-required": "You must select a destination.",
      "validation-airport-not-allowed": "You cannot select an airport as an intermediate stop.",
      "info-enter-street-number": "Please enter the street number",
      "error-no-estimate": "It seems like you have a bad connection, please try again or call our exchange 08-15 00 00 and they will help you book.",

      "header-when-do-you-want-to-go": "When can we pick you up?",
      "header-when-does-your-flight-land": "When will your flight arrive?",
      "button-continue": "Continue",
      "placeholder-flight-number": "Flight number",
      "info-flight-number": "We will monitor your flight arrival time",
      "title-baggage": "Checked Baggage",
      "info-baggage": "We take into account that it may take time to receive your checked baggage after landing",
      "validation-date-not-valid": "Date and time is not valid.",
      "validation-date-too-far-ahead": "Date and time too far ahead.",
      "validation-need-date": "You need to select a day and time.",
      "validation-need-flight-number": "You need to fill in flight number",
      "title-fully-booked": "The time is fully booked",
      "message-select-other-time": "The time is fully booked. At the moment pre-bookings are fully booked at least one hour ahead, if possible select another time or call ourcustomer service on 08-15 00 00.",
      "message-select-next-available-time": "Please pick another time or the suggested next available time.<br/><br/>The next available time is {{time}}",
      "button-select-other-time": "Pick another time",
      "button-select-available-time": "Pick suggested time",
      "button-ok": "Ok",

      "header-how-do-you-want-to-go": "Select your trip details",
      "cartype-taxi": "Taxi",
      "cartype-large-car": "People carrier",
      "cartype-business": "Business",
      "num-persons-3": "3 persons",
      "num-persons-4": "4 persons",
      "num-persons-7": "7 persons",
      "approx-min": "about {{minutes}} min",
      "title-fixed-price": "Fixed price",
      "title-booking-options": "Extras",
      "info-booking-options": "Add extra options",
      "title-pet-option": "Pet",
      "title-stationwagon-option": "Estate",
      "title-childseat-option": "Rear-facing child seat (SEK 100)",
      "info-childseat-option": "Need rear-facing child seat? Call 08-150000.",
      "title-booster-option": "Booster seat",
      "info-booster-option": "For children over 4 years or 15kg",
      "button-done": "Done",

      "label-i-want-to-go-now": "I want to go now",
      "label-from": "From",
      "label-to": "To",
      "label-when": "When",
      "label-payment": "Payment",
      "label-receipt": "Receipt",
      "label-how": "How",
      "label-fixed-price": "Fixed price",
      "label-price-information": "Price information",
      "label-total": "Total",
      "label-pet-option": "Pet",
      "label-stationwagon-option": "Estate",
      "label-booster-1-option": "1 booster seat",
      "label-booster-2-option": "2 booster seats",
      "label-booking-number": "Booking number",
      "info-available-in": "Available in about {{minutes}} minutes",
      "button-download-receipt": "Show receipt",
      "info-receipt-not-available": "Receipt not available",
      "info-receipt-pending": "Receipt available after registered payment",
      "info-receipt-refunded": "Refund completed",
      "info-num-vouchers_one": "{{count}} available voucher",
      "info-num-vouchers_other": "{{count}} available vouchers",

      "header-confirm-phone": "Verify your number",
      "info-confirm-phone": "A code will be sent to your number. We use your number to text you about important updates on your journey.",
      "info-confirm-phone-login": "To login, you must confirm your phone number.",
      "button-cancel": "Cancel",
      "validation-only-numbers": "May only contain numbers.",
      "validation-need-phone": "Phone number can not be empty.",
      "validation-length-phone": "Must be between 8 and 14 digits.",

      "header-enter-your-code": "Please enter your code",
      "info-enter-the-code-sent-to": "We sent it to number {{dialCode}} {{phone}}",
      "button-did-not-get-code": "Resend code",

      "header-enter-your-email": "What’s your email?",
      "placeholder-enter-your-email": "Enter your email address",
      "info-we-use-your-email": "We use your email to send you digital receipts.",
      "validation-invalid-email": "Invalid email address.",
      "validation-need-email": "You must enter an email address.",

      "header-welcome-back": "Welcome back!",
      "info-your-phone-is-linked": "Your phone number is linked to the email address {{maskedEmail}}. Please enter the verification code we emailed you.",
      "button-select-other-email": "Use a different email",
      "change-email-message": "If you sign in with a new email, your bookings made with the previous email will not show in your history.",

      "header-enter-name": "What’s your name?",
      "info-driver-confirm-pickup": "This helps our driver confirm it's you when they pick you up.",
      "placeholder-firstname": "First name",
      "placeholder-lastname": "Last name",
      "validation-need-firstname": "You need to fill in first name.",
      "validation-need-lastname": "You need to fill in last name.",

      "header-terms-and-conditions": "Terms and conditions",
      "info-accept-terms": "Please read and accept our <link1>terms and conditions</link1>.",
      "title-accept-terms": "I confirm that I have read and accept the terms and conditions",
      "title-accept-marketing": "I want to receive offers, news and updates from Taxi Stockholm",
      "button-confirm": "Confirm",

      "header-confirm-your-booking": "Confirm your booking",
      "placeholder-name": "Name",
      "placeholder-phone": "Phone number",
      "title-payment": "Payment",
      "title-vouchers": "Vouchers",
      "title-voucher": "Voucher:",
      "title-pay-in-car": "Pay in car",
      "title-pay-in-car-sender": "Pay in car (sender pays)",
      "button-add-new-card": "Add new payment card",
      "button-add-new-account": "Add new account",
      "button-add-new-voucher": "Add new voucher",
      "title-message-to-driver": "Message to the driver",
      "placeholder-reference": "Reference",
      "info-accept-booking-terms": "By traveling with us, you accept our <link1>terms and conditions</link1>.",
      "title-message": "Message to the driver",
      "info-message-to-driver": "Here you can clarify how the driver may find you.",
      "validation-need-name": "Name can not be empty.",
      "validation-need-reference": "You need to fill in reference.",
      "button-book-now": "Confirm",
      "error-payment": "Something went wrong with the payment. Please try again.",

      "button-cancel-booking": "Cancel booking",
      "button-close": "Close",
      "label-name": "Name",
      "label-phone": "Phone number",
      "label-message-to-driver": "Message to the driver",
      "header-thanks-for-your-booking": "Thank you for your booking!",
      "info-booking-done": "You will find your bookings under the yellow icon at the top right. By traveling with us, you agree to our <link1>terms and conditions</link1>.",
      "title-car-and-eta": "{{taxi}} arrives approx. {{eta}}",

      "tooltip-here-is-your-bookings": "Here is your bookings",
      "header-your-bookings": "Your bookings",
      "info-arrives-apx": "Approx.",
      "label-date": "Date",
      "label-flight-number": "Flight number",
      "label-baggage": "Baggage",
      "label-account": "Account",
      "label-booking-options": "Extras",
      "label-yes": "Yes",
      "label-no": "No",
      "label-message": "Message to the driver",
      "button-show-more": "Show more",
      "button-show-less": "Show less",

      "info-login-to-update-profile": "Log in to add payment methods, view travel history or update your profile.",
      "label-firstname": "First Name",
      "label-lastname": "Last Name",
      "label-mobile-phone": "Mobile phone",
      "label-communications": "Communications",
      "info-change-phone": "To use a different phone number, you need to log out and create a new account.",

      "reset-booking-message": "Do you really want to cancel your booking?",
      "reset-login-message": "Do you really want to cancel your login?",
      "button-login": "Login",
      "button-yes": "Yes",
      "button-no": "No",

      "price_basePrice": "Base price",
      "price_vat": "VAT",
      "price_terminalFee": "Terminal fee",
      "price_environmentalFee": "Environmental fee",
      "price_waitingFee": "Waiting fee",
      "price_fixedAdditionalFee": "Fixed additional fee",
      "price_tip": "Tip",

      "button-read-more": "Read more",

      "status-pre-booked": "Pre-booked",
      "status-searching": "Looking for a car",

      "menu-my-bookings": "My bookings",
      "header-previous-bookings": "Previous bookings",

      "button-book-taxi": "Book taxi",
      "button-book-delivery": "Book delivery",

      "label-as-soon-as-possible": "As soon as possible",
      "label-price": "Price",

      "header-book-delivery": "Book delivery",
      "placeholder-pickup-address": "Pickup address",
      "placeholder-delivery-address": "Delivery address",
      "button-prebook-delivery": "Pre-book delivery",
      "button-book-delivery-now": "Book delivery&nbsp;now",
      "validation-pickup-address-required": "You must select a pickup address.",
      "validation-delivery-address-required": "You must select a delivery address.",

      "header-when-should-we-pickup": "When should we pick up?",

      "header-what-would-you-like-to-send": "What do you want to send?",
      "title-ENVELOPE": "Envelope",
      "title-BOX": "Small box",
      "title-BAG": "Bag",
      "title-MOVING_BOX": "Moving box",
      "weight-0-20": "Weight 0-20 kg",
      "info-delivery-categories": "The above categories are used to determine the type of car that will be needed for your bid (max car size is station wagon).",
      "info-delivery": "If you want to send more bulky goods or have questions, call our courier service on 08-612 60 00. You can find more information about what applies to courier reservations via the web <button1>here</button1>.",
      "info-delivery-terms": "<strong>More information about our courier service via the website</strong><br/><br/><ul><li>Our courier service does not handle hazardous goods (for example petrol, cigarette lighters), heated food, tobacco, pets or larger moving transports.</li><li>Maximum weight per package is 20 kg when booking is made via our website.</li><li>10 min waiting time is included in the price, after which a waiting fee is charged.</li><li>Ferry fee may be added if the route includes a ferry with a fee.</li><li>Terminal fees are included in the price for some destinations. See current price information for terminal fees <a href=\"/en/helpdesk/fares-and-payment\" target=\"_blank\">here</a>.</li><li>In case the driver is not allowed to leave the goods at the door and the recipient is not available for delivery, the goods will be returned to the sender. The return trip will be charged.</li><li>If payment method Pay in car is selected, the payment has to be made by the sender before the trip is started.</li><li>We will not be able to help with purchase of any goods as part our service.</li><li>We will not be able to pick up packages where identification is required.</li><li>It will not be possible to fold the seat when booking is made via our website. If you want to send bulky goods (goods with large volume/dimensions) or have questions, contact our courier service on 08-612 60 00 and they will help you.</li></ul>",
      "validation-package-required": "You must select at least one type you want to send.",

      "header-whos-the-sender": "Who is the sender?",
      "placeholder-name-on-door": "Name on door",
      "placeholder-number-of-stairs": "Number of stairs",
      "placeholder-door-code": "Door code",

      "header-whos-the-receiver": "Who is the receiver?",
      "placeholder-name-receiver-on-door": "Receiver name / name on door",
      "placeholder-phone-receiver": "Receiver phone",
      "title-extra-information": "Extra information",
      "info-extra-information": "Here you can enter extra information for the driver.",
      "title-leave-outside": "Leave outside",
      "validation-name-receiver-required": "You must enter the name of the receiver.",

      "label-name-on-door": "Name on door",
      "label-number-of-stairs": "Number of stairs",
      "label-door-code": "Door code",
      "label-name-receiver-on-door": "Receiver name / name on door",
      "label-phone-receiver": "Receiver phone",

      "type-ENVELOPE_one": "{{count}} envelope",
      "type-ENVELOPE_other": "{{count}} envelopes",
      "type-BOX_one": "{{count}} small box",
      "type-BOX_other": "{{count}} small boxes",
      "type-BAG_one": "{{count}} bag",
      "type-BAG_other": "{{count}} bags",
      "type-MOVING_BOX_one": "{{count}} moving box",
      "type-MOVING_BOX_other": "{{count}} moving boxes",

      "header-account": "Account",
      "header-accounts": "Accounts",
      "header-card": "Payment card",
      "header-cards": "Payment cards",
      "header-vouchers": "Vouchers",
      "info-no-accounts": "You have not added any accounts yet.",
      "info-no-cards": "You have not added any payment cards yet.",
      "info-no-vouchers": "You have not added any vouchers yet.",
      "button-add-card": "Add payment card",
      "button-add-account": "Add account",
      "button-add-voucher": "Add voucher",
      "button-save": "Save",
      "info-confirm-remove-card": "Are you sure you want to remove the payment card from your profile?",
      "header-add-account": "Add account",
      "header-add-voucher": "Add voucher",
      "header-details": "Details",
      "placeholder-account-number": "Account number",
      "placeholder-account-password": "password",
      "placeholder-voucher-code": "Voucher code",
      "validation-need-voucher-code": "You have to enter a code.",
      "validation-need-account-number": "You have to enter an account number.",
      "validation-need-account-password": "You have to enter the password.",
      "expires-at": "Expires {{date}}",
      "free-trip": "Free ride*",
      "info-voucher-max": "Discount on first {{amount}}kr",
      "info-voucher-codes": "It's only possible to use one voucher per trip. Please see our <link1>payment terms</link1> for more information.",

      "button-send-confirmation": "Email confirmation",
      "header-send-confirmation": "Send email confirmation",
      "placeholder-email": "Email address",
      "button-send": "Send",
      "info-confirmation-sent": "A confirmation has been sent."
    }
  }
}

